<script>
import CompleteAccountForm from "./CompleteAccountForm.vue";

export default {
  name: "CompleteAccount",
  components: {
    CompleteAccountForm
  },
  data() {
    return {
      showHeading: true,
      user: null
    };
  },
  computed: {
    authUser() {
      return this.user;
    }
  },
  async mounted() {
    if (!this.$auth.user) {
      await this.loginUser();
    } else {
      this.user = this.$auth.user;
    }
  },
  methods: {
    async loginUser() {
      const { token: refreshToken } = this.$route.params;
      if (refreshToken) {
        await this.$auth.refreshAccess({ refreshToken });
        this.user = this.$auth.user;
      }
    },
    handleAccountCompletion() {
      this.showHeading = false;
    }
  }
};
</script>

<template>
  <section>
    <div class="main-container">
      <h2 v-if="showHeading">Thank you for verifying your email. Complete your account.</h2>
      <div class="form-container">
        <CompleteAccountForm v-if="authUser" :user="authUser" @accountCompleted="handleAccountCompletion" />
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import "../../scss/mixins.scss";
section {
  display: flex;
  justify-content: center;
  width: 100vw;
  .main-container {
    min-height: 75vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1700px;
    margin-top: 1rem;
    gap: 1rem;
    h2 {
      font-family: $family-primary;
      font-size: 2rem;
      line-height: 40px;
      text-align: center;
      max-width: 550px;
    }
    .form-container {
      background-color: white;
      border: 1px solid #dcdee9;
      max-width: 400px;
    }
    @include from($tablet) {
      margin: 2rem;
      gap: 2rem;
    }
  }
}
</style>
