<script>
export default {
  name: "FormSelect",
  inject: ["validation", "name"],
  props: {
    placeholder: {
      type: String,
      default: ""
    },
    options: {
      type: Array,
      default: () => []
    },
    value: {
      type: [String, Boolean],
      default: ""
    }
  },
  data() {
    return {
      innerValue: ""
    };
  },
  watch: {
    value(newVal) {
      this.innerValue = newVal;
    },
    innerValue(newVal) {
      this.$emit("input", newVal);
    }
  }
};
</script>

<template>
  <select
    :id="name"
    v-model="innerValue"
    :name="name"
    :class="{ danger: validation.$invalid && validation.$dirty }"
    @blur="validation.$touch()"
  >
    <option class="disabled" value="" disabled selected hidden>{{ placeholder }}</option>
    <option v-for="option in options" :key="option.abbrev" :value="option.abbrev">
      {{ option.name }}
    </option>
  </select>
</template>

<style lang="scss" scoped>
@import "../../scss/mixins.scss";

select {
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 1rem;
  padding: 0.75rem 1rem;
  width: 100%;
  &.danger {
    border-color: #ff3860;
  }
  &.success {
    border-color: green;
  }
}
</style>
