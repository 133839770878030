<script>
import FormField from "./FormField.vue";
import FormInput from "./FormInput.vue";
import FormSelect from "./FormSelect.vue";
import { required, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import { validateErrors, containsValidCharacters } from "./validationErrors.js";
import { ResetPassword, UpdateUserProfile } from "@/models/actions/operations.gql";
import { GET_USER_BY_USERNAME } from "@/models/users/operations.gql";
import { LIST_COUNTRIES } from "@/models/countries/operations.gql";
import { EMPTY_FIELD, INVALID_FORMAT, NOT_EXIST, UPDATE_FAILED, DEFAULT_FALLBACK, getErrorMessage, fallbackErrorMessage } from "@/backendException.js";

export default {
  name: "CompleteAccountForm",
  components: {
    FormField,
    FormInput,
    FormSelect
  },
  props: {
    user: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      password: "",
      confirmPassword: "",
      username: "",
      checkUsernameAvailabilityTimer: null,
      checkUsernameLoading: false,
      avatarColor: ["#6C39D8", "#A839D8", "#D59BF6", "#FFABE5", "#D83955", "#FB968F", "#D83991", "#FF6337", "#0C907D", "#39D862", "#A5D839", "#4ED3D0", "#399BD8", "#90B2E4", "#3942D8"][Math.floor(new Date().getSeconds() / 4)],
      countryAbbrev: null,
      countries: [],
      postalCode: "",
      agreementCheckbox: false,
      formErrorToast: null,
      accountComplete: false,
      validationReasonMap_resetPassword: new Map([
        [EMPTY_FIELD, "All fields must be filled out."],
        [INVALID_FORMAT, "Password format is invalid."],
        [DEFAULT_FALLBACK, fallbackErrorMessage]
      ]),
      executionReasonMap_resetPassword: new Map([
        [UPDATE_FAILED, "Unable to update password. Please try again or contact support if the issue persists."],
        [DEFAULT_FALLBACK, fallbackErrorMessage]
      ]),
      validationReasonMap_updateProfile: new Map([
        [EMPTY_FIELD, "Required fields must be filled out."],
        [NOT_EXIST, "User does not exist."],
        [INVALID_FORMAT, "Email format is invalid."],
        [DEFAULT_FALLBACK, fallbackErrorMessage]
      ]),
      executionReasonMap_updateProfile: new Map([
        [UPDATE_FAILED, "Update failed. Please try again."],
        [DEFAULT_FALLBACK, fallbackErrorMessage]
      ])
    };
  },
  computed: {
    validateConfirmPasswordError() {
      const genericError = validateErrors(this.$v.confirmPassword, "Password confirmation");
      if (genericError) return genericError;
      if (!this.$v.confirmPassword.sameAsPassword) return "Passwords must match.";
      return "";
    },
    validateUsernameError() {
      const genericError = validateErrors(this.$v.username, "Username");
      if (genericError) return genericError;
      if (this.$v.username.$pending) return "";
      if (!this.$v.username.available) return "Username is already taken.";
      return "";
    },
    validateUsernameSuccess() {
      const usernameErrors = this.validateUsernameError;
      if (this.$v.username.$pending) return "";
      if (!usernameErrors) return "Username is available!";
      return "";
    }
  },
  mounted() {
    if (this.user) {
      this.firstName = this.user.first_name;
      this.lastName = this.user.last_name;
    }
  },
  beforeDestroy() {
    this.clearAlerts();
  },
  methods: {
    validateErrors,
    lowercaseUsername() {
      this.username = this.username.toLowerCase();
    },
    async checkUsernameAvailability(username) {
      try {
        const response = await this.$apollo.query({
          query: GET_USER_BY_USERNAME,
          variables: { username }
        });
        const { data } = response;
        if (data.users) {
          if (data.users.length === 0) {
            return true;
          } else if (data.users.length === 1 && data.users[0].id == this.$auth.user.id) {
            this.username = data.users[0].username;
            return true;
          }
        }
        return false;
      } catch (err) {
        this.error = JSON.stringify(err.message);
        this.username_field_message = "service issue";
        return false;
      }
    },
    navTos() {
      let routeData = this.$router.resolve({ name: "tos" });
      window.open(routeData.href, "_blank");
    },
    navCode() {
      let routeData = this.$router.resolve({ name: "code_of_conduct" });
      window.open(routeData.href, "_blank");
    },
    async resetPassword() {
      try {
        const response = await this.$apollo.mutate({
          mutation: ResetPassword,
          variables: {
            userId: this.$auth.user.id,
            password: this.password
          }
        });
        const errors = response.data.result.errors;
        if (errors?.length > 0) {
          return { success: false, error: getErrorMessage(errors, this.validationReasonMap_resetPassword, this.executionReasonMap_resetPassword) };
        }
        return { success: true };
      } catch (error) {
        return { success: false, error: fallbackErrorMessage };
      }
    },
    async updateUserProfile() {
      try {
        const response = await this.$apollo.mutate({
          mutation: UpdateUserProfile,
          variables: {
            userId: this.$auth.user.id,
            email: this.$auth.user.email,
            userName: this.username,
            firstName: this.firstName,
            lastName: this.lastName,
            isLastNameHidden: true,
            countryAbbrev: this.countryAbbrev,
            postalCode: this.postalCode,
            avatarColor: this.avatarColor,
            languages: null,
            unitSystem: "metric" //for now, until it's used and until we have it in the UI
          }
        });
        const errors = response.data.result.errors;
        if (errors?.length > 0) {
          return { success: false, error: getErrorMessage(errors, this.validationReasonMap_updateProfile, this.executionReasonMap_updateProfile) };
        }
        return { success: true };
      } catch (error) {
        return { success: false, error: fallbackErrorMessage };
      }
    },
    async submitForm() {
      this.$v.$touch();
      if (this.$v.$invalid || !this.agreementCheckbox) {
        this.formErrorToast = this.$buefy.toast.open({
          message: "Please complete the form to finish your account registration.",
          type: "is-danger"
        });
      } else {
        const resetPasswordResult = await this.resetPassword();
        if (!resetPasswordResult.success) {
          this.formErrorToast = this.$buefy.toast.open({
            message: resetPasswordResult.error,
            type: "is-danger"
          });
          return;
        }
        const updateUserProfileResult = await this.updateUserProfile();
        if (!updateUserProfileResult.success) {
          this.formErrorToast = this.$buefy.toast.open({
            message: updateUserProfileResult.error,
            type: "is-danger"
          });
          return;
        }
        try {
          await this.$auth.logout({ redirectLocation: { name: "complete_account" } });
          this.accountComplete = true;
          this.$emit("accountCompleted");
          this.clearAlerts();
        } catch (error) {
          this.formErrorToast = this.$buefy.toast.open({
            message: "Unable to redirect you to the login screen. Please navigate there to log in with your new password.",
            type: "is-danger"
          });
        }
      }
    },
    clearAlerts() {
      if (this.formErrorToast) {
        this.formErrorToast.close();
      }
    },
    redirectToLogin() {
      this.$router.push({ name: "login" });
    }
  },
  validations: {
    firstName: {
      required,
      maxLength: maxLength(40)
    },
    lastName: {
      required,
      maxLength: maxLength(40)
    },
    password: {
      required,
      minLength: minLength(8),
      maxLength: maxLength(200)
    },
    confirmPassword: {
      required,
      sameAsPassword: sameAs("password")
    },
    username: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(20),
      containsValidCharacters,
      available: async function(value) {
        if (!value || value.length < 3 || value.length > 20 || !containsValidCharacters(value)) {
          return false;
        } else {
          return new Promise(resolve => {
            if (this.checkUsernameAvailabilityTimer) {
              clearTimeout(this.checkUsernameAvailabilityTimer);
              this.checkUsernameAvailabilityTimer = null;
            }
            this.checkUsernameAvailabilityTimer = setTimeout(async () => {
              try {
                const response = await this.checkUsernameAvailability(value);
                if (response) resolve(true);
                resolve(false);
              } catch (error) {
                resolve(false);
              }
            }, 500);
          });
        }
      }
    },
    countryAbbrev: {
      required
    },
    postalCode: {
      required,
      minLength: minLength(4),
      maxLength: maxLength(10)
    },
    agreementCheckbox: {
      required
    }
  },
  apollo: {
    countries: {
      query: LIST_COUNTRIES
    }
  }
};
</script>

<template>
  <form v-if="!accountComplete" @submit.prevent="submitForm">
    <FormField name="firstName" label="First Name" :validation="$v.firstName" :hide-label="true" :error-message="validateErrors($v.firstName, 'First name')">
      <FormInput v-model.trim="$v.firstName.$model" placeholder="First Name" type="text" />
    </FormField>

    <FormField name="lastName" label="Last Name" :validation="$v.lastName" :hide-label="true" :error-message="validateErrors($v.lastName, 'Last name')">
      <FormInput v-model.trim="$v.lastName.$model" placeholder="Last Name" type="text" />
    </FormField>

    <FormField name="password" label="Password:" :validation="$v.password" :hide-label="true" :error-message="validateErrors($v.password, 'Password')">
      <FormInput v-model.trim="$v.password.$model" placeholder="Enter Password" type="password" />
    </FormField>

    <FormField name="confirmPassword" label="Confirm Password:" :validation="$v.confirmPassword" :hide-label="true" :error-message="validateConfirmPasswordError">
      <FormInput v-model.trim="$v.confirmPassword.$model" placeholder="Confirm Password" type="password" />
    </FormField>

    <FormField name="username" label="Create A Username:" :validation="$v.username" :hide-label="true" :error-message="validateUsernameError" :success-message="validateUsernameSuccess">
      <FormInput v-model.trim="$v.username.$model" placeholder="Choose your username" type="text" @input="lowercaseUsername" />
    </FormField>

    <FormField name="countryAbbrev" label="Country:" :validation="$v.countryAbbrev" :hide-label="true" :error-message="validateErrors($v.countryAbbrev, 'Country')">
      <FormSelect v-model="$v.countryAbbrev.$model" :options="countries" placeholder="Select your country" />
    </FormField>

    <FormField name="postalCode" label="Postal Code:" :validation="$v.postalCode" :hide-label="true" :error-message="validateErrors($v.postalCode, 'Postal code')">
      <FormInput v-model.trim="$v.postalCode.$model" placeholder="Postal Code" type="text" />
    </FormField>

    <div class="color-field">
      <label for="avatarColor">
        Select Your Avatar Color:
      </label>
      <input id="avatarColor" v-model="avatarColor" class="color-picker" type="color" />
    </div>

    <div class="agreement-field">
      <p>By checking this box, I agree to the<br /><a @click="navTos">Terms of Use</a>&nbsp;and&nbsp;<a @click="navCode">Code of Conduct</a>.</p>
      <FormField class="checkbox" name="agreementCheckbox" label="Terms and conditions agreement" :validation="$v.agreementCheckbox" :hide-label="true">
        <b-checkbox v-model="$v.agreementCheckbox.$model" />
      </FormField>
      <p v-if="$v.agreementCheckbox.$dirty && !agreementCheckbox" class="agreement-field-error-message">Agreement is required.</p>
    </div>
    <button type="submit">Finish Account &amp; Browse Events</button>
  </form>

  <form v-else @submit.prevent="redirectToLogin">
    <p>
      You have successfully completed your account! You have been logged out and must log back in to continue.
    </p>
    <button type="submit">Log In</button>
  </form>
</template>

<style lang="scss" scoped>
@import "../../scss/mixins.scss";

form {
  display: flex;
  flex-direction: column;
  margin: 2.5rem;
  gap: 1.25rem;
  .agreement-field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.8rem;
    position: relative;
    .checkbox {
      height: 50px;
      width: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .agreement-field-error-message {
      position: absolute;
      left: 0;
      font-size: 0.75rem;
      margin-top: 47px;
      line-height: 1.1em;
      color: #ff3860;
    }
  }
  .color-field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .color-picker {
      height: 50px;
      width: 60px;
    }
  }
  button {
    background-color: white;
    border: 1px solid $border;
    border-radius: 2px;
    color: $blue;
    font-family: $family-secondary;
    font-size: 1rem;
    font-weight: 500;
    height: auto;
    margin: 1rem;
    text-align: center;
    padding: 0.75rem 1rem;
    white-space: nowrap;
    cursor: pointer;
    &:hover {
      background-color: darken(white, 5%);
    }
  }
}
</style>
